import React, { useEffect, useState } from 'react'
import Layout from '../../Common/Layout'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  PostDataV2,
  PostImageDataWithTokenV2
} from '../../../apiHelper/ApiHelperV2'
import { useSelector } from 'react-redux'
import {
  getVehicleMake,
  getVehicleModel,
  getVehiclePreviousInsurer,
  getVehicleVariant
} from '../../../apiHelper/masterService'
import ReactSelect from '../../Tags/ReactSelect'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import moment from 'moment'
import { allPos } from '../../Services/userService'
import { GetData } from '../../../apiHelper/ApiHelper'

const CreateLead = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [pincode, setPincode] = useState('')
  const [registrationNumber, setRegistrationNumner] = useState('')
  const [vType, setVType] = useState('')
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [caseType, setCaseType] = useState('')
  const [variant, setVariant] = useState('')
  const [variantData, setVariantData] = useState([])
  const location = useLocation()
  const [leadStatus, setLeadStatus] = useState('')
  const [makeData, setMakeData] = useState([])
  const [modelData, setModelData] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [ncb, setNCB] = useState(null)
  const [insurerData, setInsurerData] = useState([])
  const [fuelValue, setFuelValue] = useState(null)
  const [uniquePolicyNumber, setUniquePolicyNumber] = useState(null)
  const userDetails = useSelector(state => state?.root?.userDetails)
  const [leadId, setLeadId] = useState(null)
  const [cpaAmount, setCpaAmount] = useState('')
  const [odNetPremium, setOdNetPremium] = useState()
  const [tpNetPremium, setTpNetPremium] = useState()
  const [regDate, setRegDate] = useState('')
  const [manufacturingDate, setManufacturingDate] = useState('')
  const [policyIssuedDate, setpolicyIssuedDate] = useState('')
  const [policyStartDate, setPolicyStartDate] = useState('')
  const [posId, setPosId] = useState('')
  const [posData, setPosData] = useState([])
  const [ccFuel, setCcFuel] = useState({
    cc: '',
    fuel: ''
  })
  const [gst, setGst] = useState('')
  const [addFiles, setAddFiles] = useState({
    currentIssuedPolicy: null,
    currentIssuedPolicyPreview: null,
    rcFront: null,
    rcFrontPreview: null,
    rcBack: null,
    rcBackPreview: null,
    previousPolicy: null,
    previousPolicyPreview: null,
    salesLetter: null,
    salesLetterPreview: null,
    otherDocument: null,
    otherDocumentPreview: null
  })

  const SubmitOfflinePolicyDocument = [
    {
      id: 1,
      option: 'Current issued policy',
      name: 'current_issued_policy',
      type: 'file'
    },
    {
      id: 2,
      option: 'RC-Front',
      name: 'rc_front',
      type: 'file'
    },
    {
      id: 3,
      option: 'RC-Back',
      name: 'rc_back',
      type: 'file'
    },
    {
      id: 4,
      option: 'Previous policy',
      name: 'previous_policy',
      type: 'file'
    },
    {
      id: 5,
      option: 'Form 29/30 Sales Latter',
      name: 'sales_letter',
      type: 'file'
    },
    {
      id: 5,
      option: 'Other',
      name: 'other',
      type: 'file'
    }
  ]

  const handleUploadDocs = (e, type) => {
    console.log(e, type, 'Image New Function')
    if (!e.target.files[0].type.includes('image')) {
      console.log('Please Upload Image File')
    }
    if (type === 'current_issued_policy') {
      setAddFiles({
        ...addFiles,
        currentIssuedPolicy: e.target.files[0],
        currentIssuedPolicyPreview: URL.createObjectURL(e.target.files[0])
      })
    } else if (type === 'rc_front') {
      setAddFiles({
        ...addFiles,
        rcFront: e.target.files[0],
        rcFrontPreview: URL.createObjectURL(e.target.files[0])
      })
    } else if (type === 'rc_back') {
      setAddFiles({
        ...addFiles,
        rcBack: e.target.files[0],
        rcBackPreview: URL.createObjectURL(e.target.files[0])
      })
    } else if (type === 'previous_policy') {
      setAddFiles({
        ...addFiles,
        previousPolicy: e.target.files[0],
        previousPolicyPreview: URL.createObjectURL(e.target.files[0])
      })
    } else if (type === 'sales_letter') {
      setAddFiles({
        ...addFiles,
        salesLetter: e.target.files[0],
        salesLetterPreview: URL.createObjectURL(e.target.files[0])
      })
    } else if (type === 'other') {
      setAddFiles({
        ...addFiles,
        otherDocument: e.target.files[0],
        otherDocumentPreview: URL.createObjectURL(e.target.files[0])
      })
    }
  }
  // Premium Calculation
  useEffect(() => {
    const odNetPremiumNumber = parseFloat(odNetPremium)
    const tpNetPremiumNumber = parseFloat(tpNetPremium)
    const cpaPremium = parseFloat(cpaAmount)

    if (!isNaN(odNetPremiumNumber) && !isNaN(tpNetPremiumNumber)) {
      const netPre = odNetPremiumNumber + tpNetPremiumNumber + cpaPremium
      const gstAmount = parseFloat(gst)

      const finalPremiumNumber = netPre + gstAmount

      setValue('NetPremium', netPre)
      setValue('finalPremium', finalPremiumNumber)
    }
  }, [odNetPremium, tpNetPremium, gst])

  // Policy Date Selection
  const handlePolicyEndDate = date => {
    setPolicyStartDate(date)
    const calculatedEndDate = moment(date)
      .add(1, 'year')
      .subtract(1, 'day')
      .format('YYYY-MM-DD')
    console.log('Policy End Date', calculatedEndDate)
    setValue('policyEndDate', calculatedEndDate)
  }

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (pincode?.length >= 6) {
      GetData(`pos/get-city?pincode=${pincode}`, '').then(response => {
        if (response.status == true) {
          console.log('first fgfhfdsdf', response.data.City)
          reset({
            customerCity: response?.data?.City,
            customerState: response?.data?.State
          })
        }
      })
    }
  }, [pincode])

  useEffect(() => {
    const arr = []
    arr.push(location.state)
    setData(arr)
    setLeadStatus(location?.state?.lead_status)
    setUniquePolicyNumber(location?.state?.lead_data?.policyUniqueId)
    setLeadId(location?.state?.lead_id)
    setVType(arr[0]?.lead_data?.vehicleCategory)

    getVehicleMake({ Vehicle_Type: arr[0]?.lead_data?.vehicleCategory }).then(
      response => {
        if (response.status === true) {
          let data = response.data
          let i = 0
          let arr = []
          while (i < data.length) {
            let item = data[i]
            arr.push({
              label: item.Make,
              value: item.Make
            })
            i++
          }
          setMakeData(arr)
        }
      }
    )

    getVehicleModel({
      make: arr[0]?.lead_data?.vehicleMake,
      Vehicle_Type: arr[0]?.lead_data?.vehicleCategory
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Model,
            value: item.Model
          })
          i++
        }
        setModelData(arr)
      }
    })

    getVehicleVariant({
      make: arr[0]?.lead_data?.vehicleMake,
      model: arr[0]?.lead_data?.vehicleModel,
      Vehicle_Type: arr[0]?.lead_data?.vehicleCategory
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex(v2 => v2.Fuel_Type === v.Fuel_Type) === i
        )
        let j = 0
        let fuelarr = []
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].Fuel_Type)
          j++
        }
        setFuelTypes(fuelarr)
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Variant,
            value: item.Vehicle_Code,
            Fuel_Type: item.Fuel_Type,
            Cubic_Capacity: item.Cubic_Capacity,
            Seating_Capacity: item.Seating_Capacity,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.Kotak,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal
          })
          i++
        }
        setVariantData(arr)
      }
    })
  }, [location.state])

  useEffect(() => {
    getVehiclePreviousInsurer().then(response => {
      if (
        response?.status === true &&
        response?.message === 'All Previous Insurers Fetched Successfully'
      ) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
  }, [])

  useEffect(() => {
    setValue('currentPolicyInsurer', data[0]?.lead_data?.currentInsuerName)
  }, [])

  const handleVehicleCategory = value => {
    getVehicleMake({ Vehicle_Type: value }).then(response => {
      if (
        response?.status === true &&
        response?.message === 'All Make Fetched Successfully'
      ) {
        setMakeData(response?.data)
      }
    })
  }

  const handleVehicleType = vType => {
    setVType(vType)
    getVehicleMake({ Vehicle_Type: vType }).then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Make,
            value: item.Make
          })
          i++
        }
        setMakeData(arr)
      }
    })
  }

  const handleSelectMake = make => {
    setMake(make)
    getVehicleModel({
      make: make,
      Vehicle_Type: vType
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Model,
            value: item.Model
          })
          i++
        }
        setModelData(arr)
      }
    })
  }

  const handleSelectModel = model => {
    setModel(model)
    getVehicleVariant({
      make: make,
      model: model,
      Vehicle_Type: vType
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex(v2 => v2.Fuel_Type === v.Fuel_Type) === i
        )
        let j = 0
        let fuelarr = []
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].Fuel_Type)
          j++
        }
        setFuelTypes(fuelarr)
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Variant,
            value: item.Vehicle_Code,
            Fuel_Type: item.Fuel_Type,
            Cubic_Capacity: item.Cubic_Capacity,
            Seating_Capacity: item.Seating_Capacity,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.Kotak,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal
          })
          i++
        }
        setVariantData(arr)
      }
    })
  }

  const handleSelectVariant = val => {
    setVariant(val?.label)
  }

  const handleSelectPetrolType = val => {
    setFuelValue(val)
    if (val == 'CNG' || val == 'LPG') {
      // dispatchQuickQuote("IsInternalCNG", true);
    }
    // dispatchQuickQuote("FuelType", val);
    // dispatchQuickQuote("FuelType", val);
  }

  useEffect(() => {
    if (variantData.length > 0) {
      const logic = variantData?.filter(i =>
        i?.label === data[0]?.lead_data?.vehicleVariant ? i.label : variant
      )
      if (logic.length > 0) {
        setCcFuel({ cc: logic[0].Cubic_Capacity, fuel: logic[0].Fuel_Type })
      }
    }
  }, [variantData, variant])

  useEffect(() => {
    allPos(`?status=certified`).then(response => {
      if (response?.status) {
        let data = response?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item?.addedPos?.name + ' (' + item?.userName + ')',
            value: item?.posId
          })
          i++
        }
        setPosData(arr)
      }
    })
  }, [])

  const formatReg = e => {
    var t = e
    t = t.replace(/-/g, '')
    var o = new Array(4)
    ;(o[0] = t.slice(0, 2)), (o[1] = ''), (o[2] = ''), (o[3] = '')
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8))
    } catch (e) {}
    return o
      .join('-')
      .replace(/ /g, '')
      .replace(/--/g, '-')
      .replace(/-\s*$/, '')
      .replace(/[^- 0-9 a-z A-z]/g, '')
  }

  const setRegistrationValue = e => {
    if (navigator.userAgent.match(/UCBrowser/)) return !1
    var t = document.querySelector('#vinpuut').value,
      o = e.keyCode
    if (
      -1 != navigator.userAgent.indexOf('MSIE') ||
      1 == !!document.documentMode
    ) {
      if (32 == o || 8 == o) return !1
      t.length >= 3 && (t = formatReg(t)),
        t.length >= 5 && (t = formatReg(t)),
        t.length >= 7 && (t = formatReg(t)),
        (document.querySelector('#vinpuut').value = t.toUpperCase())
    } else
      setTimeout(function () {
        if (32 == o || 8 == o) return !1
        ;(t = document.querySelector('#vinpuut').value).length >= 3 &&
          (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector('#vinpuut').value = t.toUpperCase())
      }, 100)
    setRegistrationNumner(t)
  }

  const submitDetails = finalData => {
    const min = 10000000000
    const max = 99999999999
    const randomNumber = Math.floor(Math.random() * (max - min + 1) + min)

    let obj = {
      vehicleNumber: finalData?.vehicleNumber
    }

    const formData = new FormData()

    formData.append('posId', posId)
    formData.append('UniquePolicyId', randomNumber)
    formData.append('customerName', finalData?.customerName)
    formData.append('customerMobileNumber', finalData?.customerNumber)
    formData.append('customerEmailAddress', finalData?.customerEmailAddress)
    formData.append('customerPincode', finalData?.customerPincode)
    formData.append('customerCity', finalData?.customerCity)
    formData.append('customerState', finalData?.customerState)
    formData.append('vehicleDetails', JSON.stringify(obj))
    formData.append('vehicleMake', make)
    formData.append('vehicleModel', model)
    formData.append('vehicleVariant', variant)
    formData.append('caseType', caseType)
    formData.append('policyType', finalData?.policyType)
    formData.append('vehicleCategory', vType)
    formData.append('fuelType', fuelValue)
    formData.append('sitting_capacity', finalData?.sitting_capacity || '')
    formData.append('grossWeight', finalData?.grossWeight || '')
    formData.append('vehicle_idv', finalData?.idv || '')
    formData.append('vehicle_ncb', finalData?.ncb || '')
    formData.append('engine_number', finalData?.engineNumber)
    formData.append('chassis_number', finalData?.chassisNumber)
    formData.append('cubic_capacity', finalData?.cubicCapacity || '')
    formData.append('policyStatus', finalData?.policyStatus)
    formData.append('manufacturing_Date', finalData?.ManufaturingDate)
    formData.append('registration_date', finalData?.regDates)
    formData.append('policy_issued_date', finalData?.policyIssueDate)
    formData.append('policy_start_date', finalData?.policyStartDate)
    formData.append('policy_end_date', finalData?.policyEndDate)
    formData.append('policy_number', finalData?.policyNumber)
    formData.append('cpa', finalData?.CPA)
    formData.append('od_net_premium', finalData?.odNetPremium)
    formData.append('tp_premium', finalData?.TPPremium)
    formData.append('net_premium', finalData?.NetPremium)
    formData.append('gst_Tax_amount', finalData?.GST)
    formData.append('final_premium', finalData?.finalPremium)
    formData.append('MIS_employee_data', JSON.stringify(userDetails))
    formData.append('assignedMisName', userDetails?.name)
    formData.append('insurerName', finalData?.previousInsurerCode)
    formData.append('currentInsurer', finalData?.currentPolicyInsurer)
    formData.append('userType', 'MIS')
    formData.append('currentIssuedPolicy', addFiles.currentIssuedPolicy)
    formData.append('rcFront', addFiles.rcFront)
    formData.append('rcBack', addFiles.rcBack)
    formData.append('previousPolicy', addFiles.previousPolicy)
    formData.append('salesLetter', addFiles.salesLetter)
    formData.append('otherDocument', addFiles.otherDocument)
    formData.append('prePolicyNumber', finalData?.prePolicyNumber)
    formData.append('remark1', finalData?.remark1)
    formData.append('remark2', finalData?.remark2)

    if (posId !== '') {
      PostImageDataWithTokenV2(`admin/submit_data_MIS`, formData).then(
        response => {
          if (response?.success) {
            toast(response?.message, { type: 'success' })
            navigate('/total-booked')
          } else {
            toast(response?.message, { type: 'error' })
          }
        }
      )
    } else {
      toast('Please Select POSP', { type: 'error' })
    }
  }
  console.log('Errors', errors)
  return (
    <Layout>
      <div className='page-content-crumb'>
        <div className='breadcrumb-area'>
          <ol className='breadcrumb'>
            <li className='item'>
              <Link to='/dashboard'>
                <i className='fa fa-home' aria-hidden='true' />
              </Link>
            </li>
            <li className='item'>Create Lead</li>
          </ol>
        </div>
        <button className='btn btn-danger' onClick={() => navigate(-1)}>
          <span className='d-none d-md-block'>Back</span>
          <span className='d-block d-md-none'>
            <i className='fa fa-sign-out' aria-hidden='true'></i>
          </span>
        </button>
      </div>
      <form onSubmit={handleSubmit(submitDetails)}>
        <div className='col-12'>
          <div className='card display-card'>
            <div className='col-3 col-lg-4 form-group'>
              <div className='position-relative'>
                <div className='did-floating-label-content'>
                  <ReactSelect
                    options={posData}
                    className='did-floating-select'
                    name='pos'
                    placeholder='Select POSP'
                    onChange={val => setPosId(val.value)}
                  />
                </div>
                {/* <span className='text-danger'>{errors?.Make?.message}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className='card display-card'>
            <div className='row card-body p-0 mb-2'>
              {/* Customer Name */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    id='keyword'
                    name='keyword'
                    {...register('customerName', {
                      required: 'Field is required',
                      maxLength: {
                        value: 50,
                        message: 'Maximum 50 characters are allowed'
                      },
                      pattern: {
                        value: /^[A-Za-z\s]*$/,
                        message:
                          'Numbers and Special characters are not allowed'
                      }
                    })}
                    className='did-floating-input'
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Customer Name
                  </label>
                </div>
                {errors?.customerName && (
                  <span className='text-danger'>
                    {errors?.customerName?.message}
                  </span>
                )}
              </div>

              {/* Customer Mobile */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    id='customerNumber'
                    name=''
                    className='did-floating-input'
                    {...register('customerNumber', {
                      required: 'Field is required',
                      pattern: {
                        value:
                          /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                        message: 'Invalid Contact Number'
                      }
                    })}
                    onInput={e =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Contact Number
                  </label>
                </div>
                {errors?.customerNumber && (
                  <span className='text-danger'>
                    {errors?.customerNumber?.message}
                  </span>
                )}
              </div>

              {/* Customer Email */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='email'
                    id='customerEmailAddress'
                    name='customerEmailAddress'
                    className='did-floating-input'
                    placeholder=''
                    {...register('customerEmailAddress', {
                      required: 'Field is required',
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: 'Incorrect email format'
                      }
                    })}
                  />
                  <label className='did-floating-label' htmlFor>
                    Email
                  </label>
                </div>
                {errors?.customerEmailAddress && (
                  <span className='text-danger'>
                    {errors?.customerEmailAddress?.message}
                  </span>
                )}
              </div>

              {/* Pincode */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    id='customerPincode'
                    name='customerPincode'
                    className='did-floating-input'
                    placeholder=''
                    min={'6'}
                    maxLength={'6'}
                    {...register('customerPincode', {
                      required: 'Field is required',
                      onChange: e => setPincode(e?.target?.value)
                    })}
                  />
                  <label className='did-floating-label' htmlFor>
                    Pincode
                  </label>
                </div>
                {errors?.customerPincode && (
                  <span className='text-danger'>
                    {errors?.customerPincode?.message}
                  </span>
                )}
              </div>

              {/* City */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    id='customerCity'
                    name='customerCity'
                    className='did-floating-input'
                    placeholder='City'
                    {...register('customerCity', {
                      required: 'Field is required'
                    })}
                  />
                  <label className='did-floating-label' htmlFor>
                    City
                  </label>
                </div>
                {errors?.customerCity && (
                  <span className='text-danger'>
                    {errors?.customerCity?.message}
                  </span>
                )}
              </div>

              {/* State */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    id='customerState'
                    name='customerState'
                    className='did-floating-input'
                    placeholder='State'
                    {...register('customerState', {
                      required: 'Field is required'
                    })}
                  />
                  <label className='did-floating-label' htmlFor>
                    State
                  </label>
                </div>
                {errors?.customerState && (
                  <span className='text-danger'>
                    {errors?.customerState?.message}
                  </span>
                )}
              </div>

              {/* Vehicle Number */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content'>
                  <input
                    type='text'
                    id='vinpuut'
                    name='vehicleNumber'
                    className='did-floating-input'
                    placeholder=''
                    {...register('vehicleNumber', {
                      required: 'Field is required',
                      onChange: e => setRegistrationValue(e?.target?.value)
                    })}
                  />
                  <label className='did-floating-label' htmlFor>
                    Vehicle Number
                  </label>
                </div>
                {errors?.vehicleNumber && (
                  <span className='text-danger'>
                    {errors?.vehicleNumber?.message}
                  </span>
                )}
              </div>

              {/* Vehicle Type */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <select
                      className='did-floating-select'
                      id='vType'
                      name='vType'
                      {...register('vType', {
                        required: 'Please Select Vehicle Type',
                        onChange: e => handleVehicleType(e?.target?.value)
                      })}
                    >
                      <option>Select Vehicle Type</option>
                      <option key='' value='Pvt Car'>
                        Pvt Car
                      </option>
                      <option key='' value='MotorBike'>
                        Two Wheeler
                      </option>
                      <option key='' value='Passenger Carrying'>
                        Passenger Carrying Vehicle (PCV)
                      </option>
                      <option key='' value='Goods Carrying'>
                        Goods Carrying Vehicle (GCV)
                      </option>
                      <option key='' value='misd'>
                        MISD
                      </option>
                      <option key='' value='trailer'>
                        Trailer
                      </option>
                    </select>
                    <label className='did-floating-label' htmlFor>
                      {/* Vehicle Type */}
                    </label>
                  </div>
                </div>
                {errors?.vType && (
                  <span className='text-danger'>{errors?.vType?.message}</span>
                )}
              </div>

              {/* Vehicle Make */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <ReactSelect
                      options={makeData}
                      className='did-floating-select'
                      name='make'
                      placeholder='Select Manufacturer'
                      onChange={val => handleSelectMake(val.value)}
                    />
                  </div>
                  <span className='text-danger'>{errors?.Make?.message}</span>
                </div>
              </div>

              {/* Vehicle Model */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='form-floating inother'>
                    <ReactSelect
                      options={modelData}
                      className='form-select'
                      name='model'
                      placeholder='Select vehicle modal'
                      onChange={val => handleSelectModel(val.value)}
                    />
                  </div>
                  <span className='text-danger'>{errors?.Make?.message}</span>
                </div>
              </div>

              {/* Vehicle Variant */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='form-floating inother'>
                    <ReactSelect
                      options={variantData}
                      className='form-select'
                      name='make'
                      placeholder='Select Variant'
                      onChange={val => handleSelectVariant(val)}
                    />
                  </div>
                  <span className='text-danger'>{errors?.Make?.message}</span>
                </div>
              </div>

              {/* Cubic Capacity / Sitting Capacity / Gross Weight */}
              {vType === 'Pvt Car' ? (
                <div className='col-3 col-lg-4 form-group mb-3'>
                  <div className='did-floating-label-content input-group'>
                    <input
                      type='text'
                      name='cubicCapacity'
                      className='did-floating-input'
                      placeholder=''
                      {...register('cubicCapacity')}
                    />
                    <label className='did-floating-label' htmlFor>
                      Cubic Capacity
                    </label>
                  </div>
                </div>
              ) : vType === 'MotorBike' ? (
                <div className='col-3 col-lg-4 form-group mb-3'>
                  <div className='did-floating-label-content input-group'>
                    <input
                      type='text'
                      name='cubicCapacity'
                      className='did-floating-input'
                      placeholder=''
                      {...register('cubicCapacity')}
                    />
                    <label className='did-floating-label' htmlFor>
                      Cubic Capacity
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                </div>
              ) : vType === 'Passenger Carrying' ? (
                <div className='col-3 col-lg-4 form-group mb-3'>
                  <div className='position-relative'>
                    <div className='did-floating-label-content'>
                      <select
                        className='did-floating-select'
                        id='sitting_capacity'
                        name='sitting_capacity'
                        {...register('sitting_capacity')}
                        // onChange={e => handleSelectPetrolType(e.target.value)}
                      >
                        <option>Select Seating Capacity</option>
                        <option value={4 + 1}>4 + 1</option>
                        <option value={5 + 1}>5 + 1</option>
                        <option value={6 + 1}>6 + 1</option>
                        <option value={7 + 1}>7 + 1</option>
                        <option value={8 + 1}>8 + 1</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : vType === 'Goods Carrying' ? (
                <div className='col-3 col-lg-4 form-group mb-3'>
                  <div className='did-floating-label-content input-group'>
                    <input
                      type='text'
                      name='grossWeight'
                      id='grossWeight'
                      className='did-floating-input'
                      placeholder='Gross Weight'
                      {...register('grossWeight')}
                    />
                  </div>
                </div>
              ) : (
                <div className='col-3 col-lg-4 form-group mb-3'>
                  <div className='did-floating-label-content input-group'>
                    <input
                      type='text'
                      name='cubicCapacity'
                      className='did-floating-input'
                      placeholder=''
                      {...register('cubicCapacity')}
                    />
                    <label className='did-floating-label' htmlFor>
                      Cubic Capacity
                    </label>
                  </div>
                </div>
              )}

              {/* Fuel Type */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <select
                      className='did-floating-select'
                      selected={ccFuel?.fuel}
                      {...register('fuelType', {
                        required: 'Field is required',
                        onChange: e => setFuelValue(e.target.value)
                      })}
                    >
                      <option>Select Fuel Type</option>
                      {fuelTypes.includes('Petrol') && (
                        <option value='Petrol'>Petrol</option>
                      )}
                      {fuelTypes.includes('Diesel') && (
                        <option value='Diesel'>Diesel</option>
                      )}
                      {fuelTypes.includes('Battery') && (
                        <option value='Battery'>Electric</option>
                      )}
                      {fuelTypes.includes('CNG') && (
                        <option value='CNG'>CNG</option>
                      )}
                      {fuelTypes.includes('LPG') && (
                        <option value='LPG'>LPG</option>
                      )}
                    </select>
                  </div>
                </div>
                {errors?.fuelType && (
                  <span className='text-danger'>
                    {errors?.fuelType?.message}
                  </span>
                )}
              </div>

              {/* Manufacturing Date */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    {...register('ManufaturingDate', {
                      required: 'Field is required',
                      onChange: e => setManufacturingDate(e?.target?.value)
                    })}
                    name='ManufaturingDate'
                    max={regDate}
                    type='date'
                    id='ManufaturingDate'
                    className='did-floating-input data_picker'
                  />
                  <label className='did-floating-label' htmlFor>
                    Manufaturing Date
                  </label>
                </div>
                {errors?.ManufaturingDate && (
                  <span className='text-danger'>
                    {errors?.ManufaturingDate?.message}
                  </span>
                )}
              </div>

              {/* Registration Date */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='date'
                    name='regDates'
                    id='regDates'
                    min={manufacturingDate}
                    className='did-floating-input data_picker'
                    {...register('regDates', {
                      required: 'Field is required',
                      onChange: e => setRegDate(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Registeration Date
                  </label>
                </div>
                {errors?.regDates && (
                  <span className='text-danger'>
                    {errors?.regDates?.message}
                  </span>
                )}
              </div>

              {/* Engine Number */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    name='engineNumber'
                    className='did-floating-input'
                    maxLength={18}
                    {...register('engineNumber', {
                      required: 'Field is required',
                      pattern: {
                        value: /^[A-Za-z0-9]+$/i,
                        message: 'Invalid value'
                      },
                      minLength: {
                        value: 6,
                        message: 'Value must be minimum of 6 character'
                      }
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Engine Number
                  </label>
                </div>
                {errors?.engineNumber && (
                  <span className='text-danger'>
                    {errors?.engineNumber?.message}
                  </span>
                )}
              </div>

              {/* Chassis Number */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    name='chassisNumber'
                    maxLength={18}
                    className='did-floating-input'
                    {...register('chassisNumber', {
                      required: 'Field is required',
                      pattern: {
                        value: /^[A-Za-z0-9]+$/i,
                        message: 'Invalid value'
                      },
                      minLength: {
                        value: 6,
                        message: 'Value must be minimum of 6 character'
                      }
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Chassis Number
                  </label>
                </div>
                {errors?.chassisNumber && (
                  <span className='text-danger'>
                    {errors?.chassisNumber?.message}
                  </span>
                )}
              </div>

              {/* Case Type */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <select
                      className='did-floating-select'
                      id='caseType'
                      name='caseType'
                      {...register('caseType', {
                        required: 'Field is required'
                      })}
                      onChange={e => {
                        setCaseType(e?.target?.value)
                      }}
                    >
                      <option>Select Case Type</option>
                      <option value='new'>New</option>
                      <option value='rollover'>Rollover</option>
                      <option value='rollover-breakin'>
                        Rollover Break in
                      </option>
                      <option value='used'>Used Vehicle</option>
                    </select>
                  </div>
                </div>
                {errors?.caseType && (
                  <span className='text-danger'>
                    {errors?.caseType?.message}
                  </span>
                )}
              </div>

              {/* Policy Type */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <select
                      className='did-floating-select'
                      id='policyType'
                      name='policyType'
                      {...register('policyType', {
                        required: 'Field is required'
                      })}
                    >
                      <option>Select Policy Type</option>
                      <option value='comprehensive'>Comprehensive</option>
                      <option value='thirdParty'>Third Party</option>
                      <option value='ownDamage'>Own Damage</option>
                    </select>
                  </div>
                </div>
                {errors?.policyType && (
                  <span className='text-danger'>
                    {errors?.policyType?.message}
                  </span>
                )}
              </div>

              {caseType === 'new' ? (
                ''
              ) : (
                <>
                  {/* IDV */}
                  <div className='col-3 col-lg-4 form-group mb-3'>
                    <div className='did-floating-label-content input-group'>
                      <input
                        type='text'
                        name='idv'
                        id='idv'
                        {...register('idv', {
                          required: 'Field is required'
                        })}
                        className='did-floating-input'
                        placeholder=''
                      />
                      <label className='did-floating-label' htmlFor>
                        IDV
                      </label>
                    </div>
                    {errors?.idv && (
                      <span className='text-danger'>
                        {errors?.idv?.message}
                      </span>
                    )}
                  </div>

                  {/* NCB */}
                  <div className='col-3 col-lg-4 form-group mb-3'>
                    <div className='position-relative'>
                      <div className='did-floating-label-content'>
                        <select
                          className='did-floating-select'
                          id='ncb'
                          name='ncb'
                          {...register('ncb', {
                            required: 'Field is required',
                            onChange: e => setNCB(e?.target?.value)
                          })}
                        >
                          <option>Select NCB</option>
                          <option value={0}>0%</option>
                          <option value={20}>20%</option>
                          <option value={25}>25%</option>
                          <option value={35}>35%</option>
                          <option value={45}>45%</option>
                          <option value={50}>50%</option>
                        </select>
                      </div>
                    </div>
                    {errors?.ncb && (
                      <span className='text-danger'>
                        {errors?.ncb?.message}
                      </span>
                    )}
                  </div>

                  {/* Previous Policy Insurer */}
                  <div className='col-3 col-lg-4 form-group mb-3'>
                    <div className='position-relative'>
                      <div className='did-floating-label-content'>
                        <select
                          className='did-floating-select'
                          id='previousInsurerCode'
                          name='previousInsurerCode'
                          {...register('previousInsurerCode', {
                            required: 'Field is required'
                          })}
                        >
                          <option className='d-none' selected>
                            Previous Policy Insurer
                          </option>
                          {insurerData &&
                            insurerData.length > 0 &&
                            insurerData.map((item, i) => (
                              <option key={i} value={item.option}>
                                {item.option}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {errors?.previousInsurerCode && (
                      <span className='text-danger'>
                        {errors?.previousInsurerCode?.message}
                      </span>
                    )}
                  </div>

                  <div className='col-3 col-lg-4 form-group mb-3'>
                    <div className='did-floating-label-content input-group'>
                      <input
                        type='text'
                        name='prePolicyNumber'
                        className='did-floating-input'
                        {...register('prePolicyNumber', {
                          required: 'Field is required'
                        })}
                        placeholder=''
                        onInput={e =>
                          (e.target.value = e.target.value.replace(' ', ''))
                        }
                      />
                      <label className='did-floating-label' htmlFor>
                        Previous Year Policy Number
                      </label>
                    </div>
                    {errors?.prePolicyNumber && (
                      <span className='text-danger'>
                        {errors?.prePolicyNumber?.message}
                      </span>
                    )}
                  </div>
                </>
              )}

              {/* Current Policy insurer */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='position-relative'>
                  <div className='did-floating-label-content'>
                    <select
                      className='did-floating-select'
                      id='currentPolicyInsurer'
                      name='currentPolicyInsurer'
                      {...register('currentPolicyInsurer')}
                    >
                      <option className='d-none' selected>
                        Current Policy Insurer
                      </option>
                      {insurerData &&
                        insurerData.length > 0 &&
                        insurerData.map((item, i) => (
                          <option key={i} value={item.option}>
                            {item.option}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors?.currentPolicyInsurer && (
                  <span className='text-danger'>
                    {errors?.currentPolicyInsurer?.message}
                  </span>
                )}
              </div>

              {/* Policy Issued Date */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='date'
                    name='policyIssueDate'
                    id='policyIssueDate'
                    className='did-floating-input data_picker'
                    max={policyStartDate}
                    {...register('policyIssueDate', {
                      required: 'Field is required',
                      onChange: e => setpolicyIssuedDate(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Policy Issue Date
                  </label>
                </div>
                {errors?.policyIssueDate && (
                  <span className='text-danger'>
                    {errors?.policyIssueDate?.message}
                  </span>
                )}
              </div>

              {/* Policy Start Date */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='date'
                    name='policyStartDate'
                    id='policyStartDate'
                    min={policyIssuedDate}
                    className='did-floating-input data_picker'
                    {...register('policyStartDate', {
                      required: 'Field is required',
                      onChange: e => handlePolicyEndDate(e?.target?.value)
                    })}
                    placeholder='Policy Start Date'
                  />
                  <label className='did-floating-label' htmlFor>
                    Policy Start Date
                  </label>
                </div>
                {errors?.policyStartDate && (
                  <span className='text-danger'>
                    {errors?.policyStartDate?.message}
                  </span>
                )}
              </div>

              {/* Policy End Date */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='date'
                    name='policyEndDate'
                    id='policyEndDate'
                    className='did-floating-input data_picker'
                    {...register('policyEndDate', {
                      required: 'Field is required'
                    })}
                    placeholder='Policy End Date'
                  />
                  <label className='did-floating-label' htmlFor>
                    Policy End Date
                  </label>
                </div>
                {errors?.policyEndDate && (
                  <span className='text-danger'>
                    {errors?.policyEndDate?.message}
                  </span>
                )}
              </div>

              {/* Policy Number */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='text'
                    name='policyNumber'
                    className='did-floating-input'
                    {...register('policyNumber', {
                      required: 'Field is required'
                    })}
                    placeholder=''
                    onInput={e =>
                      (e.target.value = e.target.value.replace(' ', ''))
                    }
                  />
                  <label className='did-floating-label' htmlFor>
                    Policy Number
                  </label>
                </div>
                {errors?.policyNumber && (
                  <span className='text-danger'>
                    {errors?.policyNumber?.message}
                  </span>
                )}
              </div>

              {/* CPA */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    name='CPA'
                    step={0.000001}
                    className='did-floating-input'
                    {...register('CPA', {
                      required: 'Field is required',
                      onChange: e => setCpaAmount(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    CPA
                  </label>
                </div>
                {errors?.CPA && (
                  <span className='text-danger'>{errors?.CPA?.message}</span>
                )}
              </div>

              {/* OD Net Premium */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    name='odNetPremium'
                    step={0.000001}
                    className='did-floating-input'
                    {...register('odNetPremium', {
                      required: 'Field is required',
                      onChange: e => setOdNetPremium(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    OD Net Premium
                  </label>
                </div>
                {errors?.odNetPremium && (
                  <span className='text-danger'>
                    {errors?.odNetPremium?.message}
                  </span>
                )}
              </div>

              {/* TP Premium */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    step={0.000001}
                    name='TPPremium'
                    className='did-floating-input'
                    {...register('TPPremium', {
                      required: 'Field is required',
                      onChange: e => setTpNetPremium(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    TP Premium
                  </label>
                </div>
                {errors?.TPPremium && (
                  <span className='text-danger'>
                    {errors?.TPPremium?.message}
                  </span>
                )}
              </div>

              {/* Net Premium */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    name='NetPremium'
                    step={0.000001}
                    className='did-floating-input'
                    {...register('NetPremium', {
                      required: 'Field is required'
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Net Premium
                  </label>
                </div>
                {errors?.NetPremium && (
                  <span className='text-danger'>
                    {errors?.NetPremium?.message}
                  </span>
                )}
              </div>

              {/* GST */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    step={0.000001}
                    name='GST'
                    className='did-floating-input'
                    {...register('GST', {
                      required: 'Field is required',
                      onChange: e => setGst(e?.target?.value)
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    GST/Tax Amount
                  </label>
                </div>
                {errors?.GST && (
                  <span className='text-danger'>{errors?.GST?.message}</span>
                )}
              </div>

              {/* Final Premium */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <input
                    type='number'
                    step={0.000001}
                    name='finalPremium'
                    className='did-floating-input'
                    {...register('finalPremium', {
                      required: 'Field is required'
                    })}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Final Premium
                  </label>
                </div>
                {errors?.finalPremium && (
                  <span className='text-danger'>
                    {errors?.finalPremium?.message}
                  </span>
                )}
              </div>

              {/* Remark 1 */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <textarea
                    type='text'
                    name='remark1'
                    className='did-floating-input'
                    {...register('remark1')}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Remark 1
                  </label>
                </div>
                {errors?.remark1 && (
                  <span className='text-danger'>
                    {errors?.remark1?.message}
                  </span>
                )}
              </div>

              {/* Remark 2 */}
              <div className='col-3 col-lg-4 form-group mb-3'>
                <div className='did-floating-label-content input-group'>
                  <textarea
                    type='text'
                    name='remark2'
                    className='did-floating-input'
                    {...register('remark2')}
                    placeholder=''
                  />
                  <label className='did-floating-label' htmlFor>
                    Remark 2
                  </label>
                </div>
                {errors?.remark2 && (
                  <span className='text-danger'>
                    {errors?.remark2?.message}
                  </span>
                )}
              </div>

              <div className='card SubmitOffline-div mt-3'>
                <div className='othervoption mt-3'>
                  <div className='row mx-0 px-0'>
                    {SubmitOfflinePolicyDocument &&
                      SubmitOfflinePolicyDocument.length > 0 &&
                      SubmitOfflinePolicyDocument.map((item, index) => (
                        <div className='col-xl-4 col-lg-4 col-md-4 col-12 d-flex justify-content-center'>
                          <div className='position-relative mb-5'>
                            <p className='my-3 text-update'>{item.option}</p>
                            <div className='doccont'>
                              <input
                                type='file'
                                className=''
                                id={'abc' + index}
                                name={item.name}
                                onChange={e => handleUploadDocs(e, item?.name)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <button
              className='btn btn-primary center'
              type='submit'
              style={{ width: '20%', alignSelf: 'center' }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default CreateLead
