export const PermissionArr = [
  {
    module: 'dashboard',
    view: true,
    pos: true,
    mis: true,
    leads: true
  },
  {
    module: 'employee_desk',
    view: true
  },
  {
    module: 'employee_desk',
    subModule: 'all_employee',
    view: true,
    detail: true,
    edit: true
  },
  {
    module: 'employee_desk',
    subModule: 'add_employee',
    view: true
  },
  {
    module: 'employee_desk',
    subModule: 'active_employee',
    view: true,
    detail: true,
    edit: true
  },
  {
    module: 'employee_desk',
    subModule: 'deactivated_employee',
    view: true,
    detail: true,
    edit: true
  },
  {
    module: 'mis_desk',
    view: true
  },
  {
    module: 'mis_desk',
    subModule: 'all_mis',
    view: true,
    detail: true,
    edit: true
  },
  {
    module: 'mis_desk',
    subModule: 'create_lead',
    view: true
  },
  {
    module: 'mis_desk',
    subModule: 'pending_leads',
    view: true,
    assignBtn: true
  },
  {
    module: 'mis_desk',
    subModule: 'my_pool',
    view: true,
    actionBtn: true
  },
  {
    module: 'mis_desk',
    subModule: 'total_booked',
    view: true
  },
  {
    module: 'pos_desk',
    view: true,
    add: true,
    delete: true
  },
  {
    module: 'pos_desk',
    subModule: 'pos_desk_detail',
    view: true,
    documents: true,
    exam: true,
    certificate: true,
    edit: true,
    rejectBtn: true,
    acceptBtn: true,
    incompleteDocBtn: true,
    completeTrainingBtn: true,
    completeExamBtn: true
  },
  {
    module: 'utility',
    view: true
  },
  {
    module: 'utility',
    subModule: 'designation',
    view: true,
    edit: true
  },
  {
    module: 'utility',
    subModule: 'roles',
    view: true
  },
  {
    module: 'utility',
    subModule: 'department',
    view: true,
    edit: true
  }
]
