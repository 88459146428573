import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Permission = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const permission = JSON.parse(location?.state)
  const [dashboard, setDashboard] = useState()
  const [employeeDesk, setEmployeeDesk] = useState()
  const [allEmployee, setAllEmployee] = useState()
  const [addEmployee, setAddEmployee] = useState()
  const [activeEmployee, setActiveEmployee] = useState()
  const [deactiveEmployee, setDeactiveEmployee] = useState()
  const [misDesk, setMisDesk] = useState()
  const [createLead, setCreateLead] = useState()
  const [allMis, setAllMis] = useState()
  const [pendingLeads, setPendingLeads] = useState()
  const [myPool, setMyPool] = useState()
  const [totalBooked, setTotalBooked] = useState()
  const [posDesk, setPosDesk] = useState()
  const [posDeskDetail, setPosDeskDetail] = useState()
  const [utility, setUtility] = useState()
  const [designation, setDesignation] = useState()
  const [roles, setRoles] = useState()
  const [department, setDepartment] = useState()

  useEffect(() => {
    permission?.map((item, index) => {
      // DashBoard
      if (item?.module === 'dashboard') setDashboard(item)

      // Employee Desk
      if (item?.module === 'employee_desk' && !item?.subModule)
        setEmployeeDesk(item)
      if (
        item?.module === 'employee_desk' &&
        item?.subModule === 'all_employee'
      )
        setAllEmployee(item)
      if (
        item?.module === 'employee_desk' &&
        item?.subModule === 'add_employee'
      )
        setAddEmployee(item)
      if (
        item?.module === 'employee_desk' &&
        item?.subModule === 'active_employee'
      )
        setActiveEmployee(item)
      if (
        item?.module === 'employee_desk' &&
        item?.subModule === 'deactivated_employee'
      )
        setDeactiveEmployee(item)

      // MIS Desk
      if (item?.module === 'mis_desk' && !item?.subModule) setMisDesk(item)
      if (item?.module === 'mis_desk' && item?.subModule === 'all_mis')
        setAllMis(item)
      if (item?.module === 'mis_desk' && item?.subModule === 'create_lead')
        setCreateLead(item)
      if (item?.module === 'mis_desk' && item?.subModule === 'pending_leads')
        setPendingLeads(item)
      if (item?.module === 'mis_desk' && item?.subModule === 'my_pool')
        setMyPool(item)
      if (item?.module === 'mis_desk' && item?.subModule === 'total_booked')
        setTotalBooked(item)

      // POS Desk
      if (item?.module === 'pos_desk' && !item?.subModule) setPosDesk(item)

      //   POS Desk Detail
      if (item?.module === 'pos_desk' && item?.subModule === 'pos_desk_detail')
        setPosDesk(item)

      // Utility
      if (item?.module === 'utility' && !item?.subModule) setUtility(item)
      if (item?.module === 'utility' && item?.subModule === 'designation')
        setDesignation(item)
      if (item?.module === 'utility' && item?.subModule === 'roles')
        setRoles(item)
      if (item?.module === 'utility' && item?.subModule === 'department')
        setDepartment(item)
    })
  }, [])

  return (
    <Layout>
      <div className='page-content-crumb'>
        <div className='breadcrumb-area'>
          <ol className='breadcrumb'>
            <li className='item'>
              <Link to='/dashboard'>
                <i className='fa fa-home' aria-hidden='true' />
              </Link>
            </li>
            <li className='item'>Permissions</li>
          </ol>
        </div>
        <button className='btn btn-danger' onClick={() => navigate(-1)}>
          <span className='d-none d-md-block'>Back</span>
          <span className='d-block d-md-none'>
            <i className='fa fa-sign-out' aria-hidden='true'></i>
          </span>
        </button>
      </div>
      <div className='col-12'>
        <div className='card display-card'>
          <div>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Manager</th>
                </tr>
              </thead>
              <tbody>
                {/* Dashboard */}
                <tr>
                  <td>Dashboard</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          dashboard?.pos === false &&
                          dashboard?.mis === false &&
                          dashboard?.leads === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setDashboard({
                            ...dashboard,
                            ['module']: 'dashboard',
                            view: !dashboard?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        checked={dashboard?.pos}
                        onChange={() =>
                          setDashboard({
                            ...dashboard,
                            ['module']: 'dashboard',
                            pos: !dashboard?.pos,
                            view:
                              !dashboard?.pos === false &&
                              dashboard?.mis === false &&
                              dashboard?.leads === false
                                ? false
                                : true
                          })
                        }
                        type='checkbox'
                      />{' '}
                      POS
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={dashboard?.mis}
                        onChange={() =>
                          setDashboard({
                            ...dashboard,
                            ['module']: 'dashboard',
                            mis: !dashboard?.mis,
                            view:
                              dashboard?.pos === false &&
                              !dashboard?.mis === false &&
                              dashboard?.leads === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      MIS
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={dashboard?.leads}
                        onChange={() =>
                          setDashboard({
                            ...dashboard,
                            ['module']: 'dashboard',
                            leads: !dashboard?.leads,
                            view:
                              dashboard?.pos === false &&
                              dashboard?.mis === false &&
                              !dashboard?.leads === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Leads
                    </label>
                  </td>
                </tr>

                {/* Employee Desk */}
                <tr>
                  <td>Employee Desk</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={employeeDesk?.view}
                        onChange={() =>
                          setEmployeeDesk({
                            ...employeeDesk,
                            ['module']: 'employee_desk',
                            view: !employeeDesk?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* All Employees */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>All Employees</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          allEmployee?.detail === false &&
                          allEmployee?.edit === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setAllEmployee({
                            ...allEmployee,
                            ['subModule']: 'all_employee',
                            view: !allEmployee?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={allEmployee?.detail}
                        onChange={() =>
                          setAllEmployee({
                            ...allEmployee,
                            ['subModule']: 'all_employee',
                            detail: !allEmployee?.detail,
                            view:
                              !allEmployee?.detail === false &&
                              allEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Detail
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={allEmployee?.edit}
                        onChange={() =>
                          setAllEmployee({
                            ...allEmployee,
                            ['subModule']: 'all_employee',
                            edit: !allEmployee?.edit,
                            view:
                              allEmployee?.detail === false &&
                              !allEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>

                {/* Add Employees */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Add Employees</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={addEmployee?.view}
                        onChange={() =>
                          setAddEmployee({
                            ...addEmployee,
                            ['subModule']: 'add_employee',
                            view: !addEmployee?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* Active Employees */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Active Employees</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          activeEmployee?.detail === false &&
                          activeEmployee?.edit === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setActiveEmployee({
                            ...activeEmployee,
                            ['subModule']: 'active_employee',
                            view: !activeEmployee?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={activeEmployee?.detail}
                        onChange={() =>
                          setActiveEmployee({
                            ...activeEmployee,
                            ['subModule']: 'active_employee',
                            detail: !activeEmployee?.detail,
                            view:
                              !activeEmployee?.detail === false &&
                              activeEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Detail
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={activeEmployee?.edit}
                        onChange={() =>
                          setActiveEmployee({
                            ...activeEmployee,
                            ['subModule']: 'active_employee',
                            edit: !activeEmployee?.edit,
                            view:
                              activeEmployee?.detail === false &&
                              !activeEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>

                {/* Deactivated Employees */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Deactivated Employees</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          deactiveEmployee?.detail === false &&
                          deactiveEmployee?.edit === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setDeactiveEmployee({
                            ...deactiveEmployee,
                            ['subModule']: 'deactivated_employee',
                            view: !deactiveEmployee?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={deactiveEmployee?.detail}
                        onChange={() =>
                          setDeactiveEmployee({
                            ...deactiveEmployee,
                            ['subModule']: 'deactivated_employee',
                            detail: !deactiveEmployee?.detail,
                            view:
                              !deactiveEmployee?.detail === false &&
                              deactiveEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Detail
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={deactiveEmployee?.edit}
                        onChange={() =>
                          setDeactiveEmployee({
                            ...deactiveEmployee,
                            ['subModule']: 'deactivated_employee',
                            edit: !deactiveEmployee?.edit,
                            view:
                              deactiveEmployee?.detail === false &&
                              !deactiveEmployee?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>

                {/* MIS Desk */}
                <tr>
                  <td>MIS Desk</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={misDesk?.view}
                        onChange={() =>
                          setMisDesk({
                            ...misDesk,
                            ['module']: 'mis_desk',
                            view: !misDesk?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* All MIS */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>All MIS</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          allMis?.detail === false && allMis?.edit === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setAllMis({
                            ...allMis,
                            ['subModule']: 'all_mis',
                            view: !allMis?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={allMis?.detail}
                        onChange={() =>
                          setAllMis({
                            ...allMis,
                            ['subModule']: 'all_mis',
                            detail: !allMis?.detail,
                            view:
                              !allMis?.detail === false &&
                              allMis?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Detail
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={allMis?.edit}
                        onChange={() =>
                          setAllMis({
                            ...allMis,
                            ['subModule']: 'all_mis',
                            edit: !allMis?.edit,
                            view:
                              allMis?.detail === false &&
                              !allMis?.edit === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>

                {/* Create Lead */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Create Lead</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={createLead?.view}
                        onChange={() =>
                          setCreateLead({
                            ...createLead,
                            ['subModule']: 'create_lead',
                            view: !createLead?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* Pending Leads */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Pending Leads</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={pendingLeads?.view === false ? false : true}
                        onChange={() =>
                          setPendingLeads({
                            ...pendingLeads,
                            ['subModule']: 'pending_leads',
                            view: !pendingLeads?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={pendingLeads?.assignBtn}
                        onChange={() =>
                          setPendingLeads({
                            ...pendingLeads,
                            ['subModule']: 'pending_leads',
                            assignBtn: !pendingLeads?.assignBtn,
                            view:
                              !pendingLeads?.assignBtn === false ? false : true
                          })
                        }
                      />{' '}
                      Assign to me
                    </label>
                  </td>
                </tr>

                {/* My Pool */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>My Pool</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={myPool?.view === false ? false : true}
                        onChange={() =>
                          setMyPool({
                            ...myPool,
                            ['subModule']: 'my_pool',
                            view: !myPool?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={myPool?.actionBtn}
                        onChange={() =>
                          setMyPool({
                            ...myPool,
                            ['subModule']: 'my_pool',
                            actionBtn: !myPool?.actionBtn,
                            view: !myPool?.actionBtn === false ? false : true
                          })
                        }
                      />{' '}
                      Action
                    </label>
                  </td>
                </tr>

                {/* Total Booked */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Total Booked</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={totalBooked?.view}
                        onChange={() =>
                          setTotalBooked({
                            ...totalBooked,
                            ['subModule']: 'total_booked',
                            view: !totalBooked?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* POS Desk */}
                <tr>
                  <td>POS Desk</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          posDesk?.add === false && posDesk?.delete === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setPosDesk({
                            ...posDesk,
                            ['module']: 'pos_desk',
                            view: !posDesk?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDesk?.add}
                        onChange={() =>
                          setPosDesk({
                            ...posDesk,
                            ['module']: 'pos_desk',
                            add: !posDesk?.add,
                            view:
                              !posDesk?.add === false &&
                              posDesk?.delete === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Add
                    </label>
                  </td>

                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDesk?.delete}
                        onChange={() =>
                          setPosDesk({
                            ...posDesk,
                            ['module']: 'pos_desk',
                            delete: !posDesk?.delete,
                            view:
                              posDesk?.add === false &&
                              !posDesk?.delete === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Delete
                    </label>
                  </td>
                </tr>

                {/* POS Details */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>POS Details</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={
                          posDeskDetail?.documents === false &&
                          posDeskDetail?.exam === false &&
                          posDeskDetail?.certificate === false &&
                          posDeskDetail?.edit === false &&
                          posDeskDetail?.rejectBtn === false &&
                          posDeskDetail?.acceptBtn === false &&
                          posDeskDetail?.incompleteDocBtn === false &&
                          posDeskDetail?.completeTrainingBtn === false &&
                          posDeskDetail?.completeExamBtn === false
                            ? false
                            : true
                        }
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            view: !posDeskDetail?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.edit}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            edit: !posDeskDetail?.edit,
                            view:
                              !posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.documents}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            documents: !posDeskDetail?.documents,
                            view:
                              posDeskDetail?.edit === false &&
                              !posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Documents
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.exam}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            exam: !posDeskDetail?.exam,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              !posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Exam
                    </label>
                  </td>

                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.certificate}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            certificate: !posDeskDetail?.certificate,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              !posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Certificate
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.rejectBtn}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            rejectBtn: !posDeskDetail?.rejectBtn,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              !posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Reject
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.acceptBtn}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            acceptBtn: !posDeskDetail?.acceptBtn,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              !posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Accept
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.incompleteDocBtn}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            incompleteDocBtn: !posDeskDetail?.incompleteDocBtn,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              !posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Incomplete Doc
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.completeTrainingBtn}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            completeTrainingBtn:
                              !posDeskDetail?.completeTrainingBtn,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              !posDeskDetail?.completeTrainingBtn === false &&
                              posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Complete Training
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={posDeskDetail?.completeExamBtn}
                        onChange={() =>
                          setPosDeskDetail({
                            ...posDeskDetail,
                            ['subModule']: 'pos_desk_detail',
                            completeExamBtn: !posDeskDetail?.completeExamBtn,
                            view:
                              posDeskDetail?.edit === false &&
                              posDeskDetail?.documents === false &&
                              posDeskDetail?.exam === false &&
                              posDeskDetail?.certificate === false &&
                              posDeskDetail?.rejectBtn === false &&
                              posDeskDetail?.acceptBtn === false &&
                              posDeskDetail?.incompleteDocBtn === false &&
                              posDeskDetail?.completeTrainingBtn === false &&
                              !posDeskDetail?.completeExamBtn === false
                                ? false
                                : true
                          })
                        }
                      />{' '}
                      Complete Exam
                    </label>
                  </td>
                </tr>

                {/* Utility */}
                <tr>
                  <td>Utility</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={utility?.view}
                        onChange={() =>
                          setUtility({
                            ...utility,
                            ['module']: 'utility',
                            view: !utility?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* Designation */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Designation</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={designation?.view === false ? false : true}
                        onChange={() =>
                          setDesignation({
                            ...designation,
                            ['subModule']: 'designation',
                            view: !designation?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={designation?.edit}
                        onChange={() =>
                          setDesignation({
                            ...designation,
                            ['subModule']: 'designation',
                            edit: !designation?.edit,
                            view: !designation?.edit === false ? false : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>

                {/* Roles */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Roles</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={roles?.view}
                        onChange={() =>
                          setRoles({
                            ...roles,
                            ['subModule']: 'roles',
                            view: !roles?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                </tr>

                {/* Department */}
                <tr>
                  <td style={{ paddingLeft: '20px' }}>Department</td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        disabled
                        checked={department?.view === false ? false : true}
                        onChange={() =>
                          setDepartment({
                            ...department,
                            ['subModule']: 'department',
                            view: !department?.view
                          })
                        }
                      />{' '}
                      View
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        type='checkbox'
                        checked={department?.edit}
                        onChange={() =>
                          setDepartment({
                            ...department,
                            ['subModule']: 'department',
                            edit: !department?.edit,
                            view: !department?.edit === false ? false : true
                          })
                        }
                      />{' '}
                      Edit
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Permission
